export const members = [
    {
        id: "00",
        name: "ikooka",
        team: "planing",
        role: "PD & Story",
        link: "https://grape-sushi-a31.notion.site/2d82b6d686f74b49ab421eb1a27b4310",
        text: "쿠카",
        state: true,
        period: null,
    },
    {
        id: "01",
        name: "gimkori",
        team: "planing",
        role: "Game Design",
        link: "hhttps://grape-sushi-a31.notion.site/6c1b43c5b2ec4abb91c40a9a758b247c",
        text: "코리",
        state: false,
        period: "2022.04~2023.07",
    },
    {
        id: "02",
        name: "ssiho",
        team: "planing",
        role: "Sound",
        link: "https://grape-sushi-a31.notion.site/d216f30557d84699954c10052e51cac3",
        text: "씨호",
        state: true,
        period: null,
    },
    {
        id: "03",
        name: "chyoke",
        team: "development",
        role: "Client",
        link: "https://grape-sushi-a31.notion.site/d73b6bd6093c41dcb9f02f61fd8fbb97",
        text: "초케",
        state: false,
        period: "2022.06~2023.08 ",
    },
    {
        id: "04",
        name: "huttooru",
        team: "development",
        role: "Client",
        link: "https://grape-sushi-a31.notion.site/6e4601a643c74634abf20d63412844ef",
        text: "후뚜루",
        state: false,
        period: "2022.06~2023.08 ",
    },
    {
        id: "05",
        name: "billy",
        team: "development",
        role: "Server",
        link: "https://grape-sushi-a31.notion.site/be7541e1dc784358a10d011e66eb766d",
        text: "빌리",
        state: false,
        period: "2022.09~2023.08",
    },
    {
        id: "06",
        name: "lumi",
        team: "development",
        role: "Client",
        link: "https://grape-sushi-a31.notion.site/05280fc868474fdeb3fe4f2f19f4fdbe",
        text: "루미",
        state: true,
    },
    {
        id: "07",
        name: "ichelly",
        team: "planing",
        role: "Marketing",
        link: "https://grape-sushi-a31.notion.site/c6190cf89a9a462fb97ee5b4be9cd78a",
        text: "첼리",
        state: true,
    },
    {
        id: "08",
        name: "yundaram",
        team: "artDesign",
        role: "Web Publish & Design",
        link: "https://grape-sushi-a31.notion.site/7475cdfa3edd4e2fb7d0b867e2e8a456",
        text: "다람",
        state: true,
    },
    {
        id: "09",
        name: "shinguri",
        team: "artDesign",
        role: "Artist",
        link: "https://grape-sushi-a31.notion.site/b81991efc6dc47b885135a53d8ec9a40",
        text: "구리",
        state: true,
    },
    {
        id: "10",
        name: "mabaem",
        team: "artDesign",
        role: "Character Artist",
        link: "https://grape-sushi-a31.notion.site/9140e28cfd34476992b6ac9171d1777d",
        text: "마뱀",
        state: true,
    },
    {
        id: "11",
        name: "dahy",
        team: "art",
        role: "Main Art",
        link: "https://www.notion.so/DAHY-c16a7652ec10460988d89824629b12e1",
        text: "다히",
        state: false,
        period: "2022.05~2023.05",
    },
    {
        id: "12",
        name: "gimchurial",
        team: "marketing",
        role: "Marketer",
        link: "https://grape-sushi-a31.notion.site/90692bf1232f436ebec5bf5dfc8d224a",
        text: "김추리알",
        state: false,
        period: "2022.08~2023.03",
    },
    {
        id: "13",
        name: "yeha",
        team: "design",
        role: "Main Design",
        link: "https://grape-sushi-a31.notion.site/c824176cc35448c197b22d647a6fb5a0",
        text: "예하",
        state: false,
        period: "2022.09~2023.06",
    },
    {
        id: "14",
        name: "dongdong",
        team: "design",
        role: "Design",
        link: "https://grape-sushi-a31.notion.site/c1ac49a6ad59461491996cd7eed862f4",
        text: "동동",
        state: false,
        period: "2023.01~2023.05",
    },
    {
        id: "15",
        name: "wooung",
        team: "development",
        role: "Client",
        link: "https://grape-sushi-a31.notion.site/35ac565272244d8d85a01913921e75af",
        text: "우엉",
        state: false,
        period: "2023.02~2023.08",
    },
    {
        id: "16",
        name: "kodil",
        team: "development",
        role: "Client",
        link: "https://grape-sushi-a31.notion.site/f500306b3d564c26a03f635634d753d8",
        text: "코딜",
        state: true,
    },
    {
        id: "17",
        name: "sample",
        team: "design",
        role: "Design",
        link: "https://grape-sushi-a31.notion.site/d0916237f2554939b9d0e67c191912ae",
        text: "하은",
        state: false,
        period: "2023.03~2023.06",
    },
    {
        id: "18",
        name: "sample",
        team: "development",
        role: "Server",
        link: "https://grape-sushi-a31.notion.site/f4668fb8138b4063a82705faccf4c71f",
        text: "유진",
        state: false,
        period: "2023.05~2023.08",
    },
    {
        id: "19",
        name: "munji",
        team: "artDesign",
        role: "Art",
        link: "https://grape-sushi-a31.notion.site/73e3129f68ae4ea7a1f6ee050953b9de",
        text: "먼지",
        state: true,
    },
    {
        id: "20",
        name: "dubu",
        team: "artDesign",
        role: "UI Design",
        link: "https://grape-sushi-a31.notion.site/85894e0a51d140b2a7ff3d2d2fd1c9aa?pvs=4",
        text: "두부",
        state: true,
    },
];
