<template>
    <Teleport to="body">
        <aside class="aside">
            <swiper
                :slidesPerView="'auto'"
                :initialSlide="1"
                :pagination="pagination"
                :modules="modules"
                class="bannerSwiper"
                :loop="true"
                :autoplay="{
                    delay: 2500,
                }"
            >
                <swiper-slide v-for="banner in bannerList" :key="banner">
                    <a :href="banner.url" traget="_blank">
                        <img :src="require(`@/assets/banner/${banner.img}`)" :alt="banner.alt" />
                    </a>
                </swiper-slide>
            </swiper>
            <div class="banner_pagination"></div>
        </aside>
    </Teleport>
</template>

<script>
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
    name: "SideBanner",
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {},
    data() {
        return {
            bannerList: [
                {
                    url: "https://tumblbug.com/lostlibrary",
                    img: "funding_skin_banner.png",
                    alt: "잊혀진 서재 PC 확장판 출시! 텀블벅 알림신청 시작",
                },
                {
                    url: "https://www.instagram.com/p/C3hoew5BhWM/?igsh=MXNianpiODlvamkxdg==",
                    img: "palace_update_banner.png",
                    alt: "잊혀진 서재 청룡의 해 업데이트 안내 - 신규출시! 푸른 용의 궁 거리 스킨, 신규 챕터 업데이트! 제2장 다섯소녀",
                },
                {
                    url: "http://sonaksdp.com/link/",
                    img: "banner_game_download.png",
                    alt: "잊혀진서재 다운로드 바로가기",
                },
            ],
            cardNumber: 4,
        };
    },
    methods: {},
    setup() {
        return {
            pagination: {
                el: ".banner_pagination",
            },
            modules: [Autoplay, Pagination, Navigation],
        };
    },
};
</script>

<style scoped>
.aside {
    position: fixed;
    right: 4rem;
    top: 50%;
    z-index: 900;

    width: 16rem;
    max-height: 60%;
    transform: translateY(-50%);
    transition: opacity 1s;
    opacity: 1;
}
.aside.fade {
    opacity: 0;
}
.aside .banner_pagination {
    display: flex;
    justify-content: center;
    padding: 16px 0;
}
@media all and (max-width: 1620px) {
    .aside {
        display: none;
    }
}
</style>
<style>
.aside .banner_pagination .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 0 4px;
}
.aside .banner_pagination .swiper-pagination-bullet-active {
    background-color: var(--color-dark);
}
</style>
